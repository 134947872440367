import React, { useEffect, useState } from "react";
import "./App.css";
import { localquotes } from "./quotes";
import axios from 'axios';

const QUOTES_COLLECTION_NAME = "quotes"
const quotesUrl = process.env.REACT_APP_QUOTES_URL;
const DEFAULT_QUOTE = {
    quote: "Life is like a camera, focus on the good times, develop from the negatives, and if things don't work out, take another shot.",
    author: "Unknown"
};

const CHANGE_BK_AND_QUOTE_TIMEOUT = 60000;

function App() {
    const [backgroundUrl, setBackgroundUrl] = useState(`https://source.unsplash.com/random/1920x1080/?travel`);
    const [nextBackgroundUrl, setNextBackgroundUrl] = useState(`https://source.unsplash.com/random/1920x1080/?travel&random=${new Date().getTime()}`);
    const [selectedQuote, setSelectedQuote] = useState(DEFAULT_QUOTE);


    useEffect(() => {
        const changeQuote = async () => {
            const result = await axios.get(quotesUrl + '/quotes/random');
            setSelectedQuote(result.data);
        };

        const intervalQuote = setInterval(() => {
            changeQuote()
        }, CHANGE_BK_AND_QUOTE_TIMEOUT);

        const intervalBkg = setInterval(() => {
            setNextBackgroundUrl(`https://source.unsplash.com/random/1920x1080/?travel&random=${new Date().getTime()}`);
        }, CHANGE_BK_AND_QUOTE_TIMEOUT);

        return () => {
            clearInterval(intervalQuote);
            clearInterval(intervalBkg);
        };
    }, []);



    useEffect(() => {
        setTimeout(() => {
            setBackgroundUrl(nextBackgroundUrl);
        }, 5000);
    }, [nextBackgroundUrl]);

    return (
        <div className="bg" style={{ backgroundImage: `url(${backgroundUrl})` }}>
            <div style={{ backgroundImage: `url(${nextBackgroundUrl})`, visibility: "hidden" }}/>
            <div className="quote-container">
                <div className="quote">
                    <p className="quote-text">{selectedQuote.quote}</p>
                </div>
                <p className="source">- {selectedQuote.author}</p>
            </div>
        </div>
    );
}

const getQuotes = () => {
    //return await getDocs(collection(firestore, QUOTES_COLLECTION_NAME));
    return new Promise((resolve, reject) => {
        resolve(localquotes);
    });
}

/*const getQuote = async () => {
    const quote = await fetchQuote().then(res => res);
    return quote.data;
}

const fetchQuote = () => {
    return axios.get('http://localhost:8080/quotes/random');
}*/

export default App;
